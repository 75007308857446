.ezy__footer2_wvF1P7RQ {
	/* Bootstrap variables */
	--bs-body-color: #28303b;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: #4691f6;;
	--ezy-theme-color-rgb: 13, 110, 253;

	background-color: var(--bs-body-bg);
	color: var(--bs-body-color);
	padding: 60px 0;
}

@media (min-width: 768px) {
	.ezy__footer2_wvF1P7RQ {
		padding: 80px 0;
	}
}

/* Gray Block Style */
.gray .ezy__footer2_wvF1P7RQ,
.ezy__footer2_wvF1P7RQ.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);
}

/* Dark Gray Block Style */
.dark-gray .ezy__footer2_wvF1P7RQ,
.ezy__footer2_wvF1P7RQ.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
}

.dark .ezy__footer2_wvF1P7RQ,
.ezy__footer2_wvF1P7RQ.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);
}

.ezy__footer2_wvF1P7RQ-nav .nav-link {
	color: var(--bs-body-color);
}

.ezy__footer2_wvF1P7RQ-nav .nav-link:hover {
	color: var(--bs-body-color);
	opacity: 0.8;
}

.ezy__footer2_wvF1P7RQ-social li {
	list-style-type: none;
	display: inline-block;
	margin: 6px 8px;
}

.ezy__footer2_wvF1P7RQ-social li a {
	color: var(--bs-body-color);
	width: 35px;
	height: 35px;
	text-decoration: none;
}

.ezy__footer2_wvF1P7RQ-social li a:hover {
	color: var(--ezy-theme-color);
}

.ezy__footer2_wvF1P7RQ-quick-links li {
	margin-bottom: 8px;
}

.ezy__footer2_wvF1P7RQ-quick-links li a {
	color: var(--bs-body-color);
	opacity: 0.7;
	text-decoration: none;
}

.ezy__footer2_wvF1P7RQ-quick-links li a:hover {
	color: var(--bs-body-color);
	opacity: 1;
}