.ezy__header23_lw6KpiU7 {
	/* Bootstrap variables */
	--bs-body-color: #373572;
	--bs-body-bg: #fff;

	/* Easy Frontend variables */
	--ezy-theme-color: #4691f6;;
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-shadow: 0px 4px 44px rgba(159, 190, 218, 0.37);

	background-color: var(--bs-body-bg);
	overflow: hidden;
	padding: 60px 0 0 0;
	position: relative;
}

@media (min-width: 768px) {
	.ezy__header23_lw6KpiU7 {
		padding: 100px 0 0 0;
	}
}

/* Gray Block Style */
.gray .ezy__header23_lw6KpiU7,
.ezy__header23_lw6KpiU7.gray {
	/* Bootstrap variables */
	--bs-body-bg: #fffbf7;
}

/* Dark Gray Block Style */
.dark-gray .ezy__header23_lw6KpiU7,
.ezy__header23_lw6KpiU7.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);

	/* Easy Frontend variables */
	--ezy-shadow: 0px 4px 44px rgba(0, 0, 0, 0.37);
}

/* Dark Block Style */
.dark .ezy__header23_lw6KpiU7,
.ezy__header23_lw6KpiU7.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);

	/* Easy Frontend variables */
	--ezy-shadow: 0px 4px 44px rgba(0, 0, 0, 0.37);
}

.ezy__header23_lw6KpiU7-heading {
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	color: var(--bs-body-color);
}

@media (min-width: 768px) {
	.ezy__header23_lw6KpiU7-heading {
		font-size: 70px;
		line-height: 85px;
	}
}

.ezy__header23_lw6KpiU7-sub-heading {
	color: var(--bs-body-color);
	font-size: 22px;
	line-height: 1.5;
	opacity: 0.8;
}

.ezy__header23_lw6KpiU7-content-wrapper {
	padding-bottom: 60px;
}

@media (min-width: 991px) {
	.ezy__header23_lw6KpiU7-content-wrapper {
		padding-top: 60px;
		padding-bottom: 130px;
	}
}

.ezy__header23_lw6KpiU7-btn {
	padding: 12px 30px;
	background-color: var(--ezy-theme-color);
	border-color: var(--ezy-theme-color);
	color: #fff;
	box-shadow: var(--ezy-shadow);
}

.ezy__header23_lw6KpiU7-btn:hover {
	background-color: rgba(var(--ezy-theme-color-rgb), 0.9);
	border-color: rgba(var(--ezy-theme-color-rgb), 0.9);
}

.ezy__header23_lw6KpiU7-shape-1 {
	position: absolute;
	left: 0;
	top: 0;
}

.ezy__header23_lw6KpiU7-shape-2 {
	position: absolute;
	bottom: 0;
	left: 25%;
	top: 0;
}

@media (min-width: 991px) {
	.ezy__header23_lw6KpiU7-shape-2 {
		left: 45%;
	}
}