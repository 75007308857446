.ezy__clients9_ezTulFx3 {
	/* Bootstrap variables */
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: #4691f6;
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-item-bg: #f4f7fd;

	background-color: var(--bs-body-bg);
	overflow: hidden;
	padding: 60px 0;
}

@media (min-width: 768px) {
	.ezy__clients9_ezTulFx3 {
		padding: 100px 0;
	}
}

/* Gray Block Style */
.gray .ezy__clients9_ezTulFx3,
.ezy__clients9_ezTulFx3.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-item-bg: #fff;
}

/* Dark Gray Block Style */
.dark-gray .ezy__clients9_ezTulFx3,
.ezy__clients9_ezTulFx3.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);

	/* Easy Frontend variables */
	--ezy-item-bg: #243344;
}

/* Dark Block Style */
.dark .ezy__clients9_ezTulFx3,
.ezy__clients9_ezTulFx3.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);

	/* Easy Frontend variables */
	--ezy-item-bg: #162231;
}

.ezy__clients9_ezTulFx3-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

@media (min-width: 768px) {
	.ezy__clients9_ezTulFx3-heading {
		font-size: 45px;
		line-height: 45px;
	}
}

.ezy__clients9_ezTulFx3-sub-heading {
	font-size: 18px;
	line-height: 35px;
	color: var(--bs-body-color);
	opacity: 0.7;
}

.ezy__clients9_ezTulFx3-item {
	background-color: var(--ezy-item-bg);
	border-radius: 10px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ezy__clients9_ezTulFx3-img {
	max-height: 50px;
}