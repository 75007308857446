.ezy__service23_OGANd7P3 {
	--bs-body-color: rgb(50, 62, 80);
	--bs-body-bg: #fff;

	/* Easy Frontend variables */
	--ezy-theme-color: #4691f6;
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-card-bg: #ffffff;
	--ezy-card-shadow: 0px 6px 44px rgba(173, 174, 197, 0.2);
	--ezy-icon-shadow: 0px 20px 40px 0 rgba(234, 233, 233, 0.92);

	background-color: var(--bs-body-bg);
	color: var(--bs-body-color);
}

/* Gray Block Style */
.gray .ezy__service23_OGANd7P3,
.ezy__service23_OGANd7P3.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-card-bg: #ffffff;
}

/* Dark Gray Block Style */
.dark-gray .ezy__service23_OGANd7P3,
.ezy__service23_OGANd7P3.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);

	/* Easy Frontend variables */
	--ezy-card-bg: rgb(30, 39, 53);
	--ezy-card-shadow: 0px 6px 44px rgba(0, 0, 0, 0.2);
	--ezy-icon-shadow: 0px 20px 40px 0 rgba(0, 0, 0, 0.2);
}

/* Dark Block Style */
.dark .ezy__service23_OGANd7P3,
.ezy__service23_OGANd7P3.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);

	/* Easy Frontend variables */
	--ezy-card-bg: rgb(30, 39, 53);
	--ezy-card-shadow: 0px 6px 44px rgba(0, 0, 0, 0.2);
	--ezy-icon-shadow: 0px 20px 40px 0 rgba(0, 0, 0, 0.2);
}

.ezy__service23_OGANd7P3-heading {
	font-size: 32px;
	font-weight: 700;
	line-height: 1;
}

@media (min-width: 768px) {
	.ezy__service23_OGANd7P3-heading {
		font-size: 45px;
	}
}

.ezy__service23_OGANd7P3-sub-heading {
	font-size: 17px !important;
	line-height: 1.5;
	opacity: 0.8;
}

.ezy__service23_OGANd7P3-separator {
	padding: 80px 0;
}

.ezy__service23_OGANd7P3-card {
	background-color: var(--ezy-card-bg);
	box-shadow: var(--ezy-card-shadow);
	border-radius: 10px !important;
	transition: 0.4s;
}

.ezy__service23_OGANd7P3-card-icon {
	font-size: 30px;
	padding: 20px;
	width: 70px;
	height: 70px;
	/* color: #fff; */
	background-color: transparent;
	box-shadow: var(--ezy-icon-shadow);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ezy__service23_OGANd7P3-row [class*="col-"]:nth-of-type(1n) .ezy__service23_OGANd7P3-card-icon {
	color: #ffc107;
}

.ezy__service23_OGANd7P3-row [class*="col-"]:nth-of-type(2n) .ezy__service23_OGANd7P3-card-icon {
	color: #ff6400;
}

.ezy__service23_OGANd7P3-row [class*="col-"]:nth-of-type(3n) .ezy__service23_OGANd7P3-card-icon {
	color: #2f80ed;
}

.ezy__service23_OGANd7P3-row [class*="col-"]:nth-of-type(4n) .ezy__service23_OGANd7P3-card-icon {
	color: #3abdbb;
}

.ezy__service23_OGANd7P3-row [class*="col-"]:nth-of-type(5n) .ezy__service23_OGANd7P3-card-icon {
	color: #04004d;
}

.ezy__service23_OGANd7P3-row [class*="col-"]:nth-of-type(6n) .ezy__service23_OGANd7P3-card-icon {
	color: #ff1f1f;
}

.ezy__service23_OGANd7P3-card-title {
	font-size: 24px;
	font-weight: 700;
}

.ezy__service23_OGANd7P3-card-sub-title {
	font-size: 16px;
	opacity: 0.8;
}