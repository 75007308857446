/* src/components/ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    border-radius: 50%;
    background-color: #007bff; /* Adjust color as needed */
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.scroll-to-top:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}
